jQuery(document).ready(function ($) {

    $('#wp-admin-bar-fw-package a').on('click', function (e) {
        e.preventDefault();
        toastr.info('Packaging and uploading theme')
        $.ajax({
            url: '/wp-json/development/package',
            type: 'post',
            headers: {
                "X-WP-Nonce": window.wprest.nonce
            },
            dataType: 'json',
            success: function (data) {
                toastr.success('Theme Packaged')
            },
            fail: function (data) {
                toastr.error('Failed to package theme')
            }
        })
    });

    $('#wp-admin-bar-fw-recompile-scss a').on('click', function (e) {
        // stop button from going somewhere
        e.preventDefault();

        toastr.info('Compiling CSS')

        // call wp-json endpoint to compile scss
        $.ajax({
            url: '/wp-json/development/compile-scss',
            type: 'post',
            headers: {
                "X-WP-Nonce": window.wprest.nonce
            },
            dataType: 'json',
            success: function (data) {
                let queryString = '?reload=' + new Date().getTime();
                $('link[rel="stylesheet"]').each(function () {
                    this.href = this.href.replace(/\?.*|$/, queryString);
                });
                toastr.success('Successfully compiled css')
            },
            fail: function (data) {
                toastr.error('Failed to compile css')
            }
        });
    });

    // HEADER SEARCH INPUT SHOW/HIDE
    $('.search-btn-header').on('click', function () {
        $('.header-search section.widget_search').css('display', 'flex');
        $('.search-btn-header').css('z-index', '0');
        $('.header-search .search-field').focus();
    });

    $('.header-search .search-field').on('blur', function () {
        $('.header-search section.widget_search').css('display', 'none');
        $('.search-btn-header').css({
            'z-index': '2'
        });
    });

    // Blog Categories Slide Toggle
    $('.show-categories').click(function () {
        $('.show-categories  + ul').slideToggle();
    });

    // Don't display sidebar-block if no text
    if (!$.trim($('#extra-sidebar-block').html()).length) {
        $('#extra-sidebar-block').css('display', 'none');
    }

    // SIDE NAV FUNCTIONALITY
    var $sidenav = $('.sidenav'),
        $toggler = $('.navbar-toggler'),
        collapsed = false;

    $sidenav.css('top', $('.navbar').outerHeight());

    if (window.innerWidth < 1200) {
        collapse();
    }

    $(window).resize(function () {
        if (window.innerWidth < 1200) {
            collapse();
        } else {
            restore();
        }
    });

    $toggler.click(function () {
        if (!$sidenav.hasClass('show')) {
            showSidenav();
        } else {
            hideSidenav();
        }
    })

    function showSidenav() {
        $sidenav.css('display', 'flex');
        window.setTimeout(function () {
            // Must be number value. Adjust as needed.
            $sidenav.css({
                'margin-left': '0px',
                'background-color': '#BB3357'
            });
        }, 10);
        $toggler.css('color', '#EDEBDE');
        $sidenav.addClass('show');
    }

    function hideSidenav() {
        $sidenav.css({
            'margin-left': '-200px',
            'background': 'transparent'
        });
        window.setTimeout(function () {
            $sidenav.css('display', 'none');
        }, 500);
        $toggler.css('color', '#BB3357');
        $sidenav.removeClass('show');
    }

    function collapse() {
        if (!collapsed) {
            $('.navbar-collapse > div').hide();

            $('.sidenav > ul .nav-link').addClass('w-100');
            collapsed = true;
        }
    }

    function restore() {
        if (collapsed) {
            $('.navbar-collapse > div').show();
            $('.sidenav > ul .nav-link').removeClass('w-100');
            hideSidenav();
            collapsed = false;
        }
    }

    //Raplace subheading strings and move it inside a span tag
    const subheadings = $('.subheading');

    for (i = 0; i < subheadings.length; i++) {
        for (j = 0; j < subheadings[i].getElementsByTagName('p').length; j++) {
            subheadings[i].getElementsByTagName('p')[j].innerHTML = "<span>" + subheadings[i].getElementsByTagName('p')[j].innerHTML + "</span>";
        }
    }
});







