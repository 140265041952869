let sliders = {
  init: function() {
    $('#hero_banner').not('slick-initialized').slick({
      fade: true,
      infinite: true,
      dots: true,
      appendDots: $('.banner-dots'),
      arrows: true,
      appendArrows: $('.banner-controls'),
      prevArrow: '<div class="arrow-prev"></div>',
      nextArrow: '<div class="arrow-next"></div>',
      adaptiveHeight: true,
    });

    $('#header_banner').not('slick-initialized').slick({
      speed: 750,
      fade: true,
      cssEase: 'ease-in-out',
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3500,
      arrows: false,
      adaptiveHeight: true
    });

    $('.testimonial-slider').not('slick-initialized').slick({
      fade: true,
      infinite: true,
      dots: true,
      autoplay: true,
      appendDots: $('.testimonial-dots'),
      arrows: true,
      appendArrows: $('.testimonial-controls'),
      prevArrow: '<div class="arrow-prev"></div>',
      nextArrow: '<div class="arrow-next"></div>',
      adaptiveHeight: true,
    });
  }
};

$(document).ready(function() {
  let Sliders = Object.create(sliders);
  Sliders.init();
});
