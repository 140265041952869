// Load popperjs
window.Popper = require('popper.js').default;
// Load jquery
window.$ = require('jquery');
// Load bootstrap
require('bootstrap');
// reference window.jQuery as window.$
window.jQuery = window.$;
// Load slick carousel
require('slick-carousel');
// Load a custom javascript file
require('./sliders');
require('./admin.js');

//window._ = require('lodash');
// window.axios = require('axios');
// window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//
// window.Vue.component('example-component', require('./components/ExampleComponent.vue'));
//
// const app = new window.Vue({
//   el: '#app',
// });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Wordpress Json back-end. This library automatically handles sending the
 * Nonce token as a header based on the value of the "nonce" token cookie.
 */

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//
// const token = window.wp.nonce;
//
// if (token) {
//   window.axios.defaults.headers.common['X-WP-Nonce'] = token;
// } else {
//   console.error('nonce token not found');
// }


